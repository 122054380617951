import React, { useState, useEffect, useContext, useRef } from "react"

import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography";

import useTickets from "../../hooks/useTickets"

import { AuthContext } from "../../context/Auth/AuthContext";

import { i18n } from "../../translate/i18n";

import Chart from "./Chart"

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 240,
	},
	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 120,
	},
	customFixedHeightPaperLg: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "100%",
	},
}))

const Dashboard = () => {
	const classes = useStyles()

	const [peakTime, setPeakTime] = useState(""); // Estado para armazenar o horário de pico

	const handlePeakTimeChange = (time) => {
		setPeakTime(time); // Atualiza o estado com o horário de pico recebido do Chart
	};
	const [averageDuration, setAverageDuration] = useState(0); // Estado para armazenar a média de duração

	const { user } = useContext(AuthContext);
	var userQueueIds = [];

	if (user.queues && user.queues.length > 0) {
		userQueueIds = user.queues.map(q => q.id);
	}

	const GetTickets = (status, showAll, withUnreadMessages) => {

		const { count } = useTickets({
			status: status,
			showAll: showAll,
			withUnreadMessages: withUnreadMessages,
			queueIds: JSON.stringify(userQueueIds)
		});
		return count;
	}

	const GetClosedTicket = (date) => {

		const { tickets } = useTickets({status: "closed", showAll: "true", withUnreadMessages:"false", date: date.current });

		return tickets

	}

	const date = useRef(new Date().toISOString());
	const closedTickets = GetClosedTicket(date)
	

	useEffect(() => {
		// Função para calcular a média de duração dos tickets fechados
		const calculateAverageDuration = (closedTickets) => {
		  if (closedTickets.length === 0) {
			return 0; // Retorna 0 se não houver tickets fechados
		  }
	
		  const totalDuration = closedTickets.reduce((accumulator, ticket) => {
			const createdAt = new Date(ticket.createdAt).getTime(); // Timestamp do ticket criado
			const updatedAt = new Date(ticket.updatedAt).getTime(); // Timestamp do ticket atualizado
	
			const durationInMinutes = (updatedAt - createdAt) / (1000 * 60); // Calcula a diferença de tempo em minutos
			return accumulator + durationInMinutes; // Soma a duração de cada ticket
		  }, 0);
	
		  const averageDuration = totalDuration / closedTickets.length; // Calcula a média dividindo pela quantidade de tickets
	
		  return averageDuration;
		};
		
		// Calcula a média de duração dos tickets fechados
		const avgDuration = calculateAverageDuration(closedTickets);
	
		setAverageDuration(avgDuration); // Define a média de duração no estado
	  }, [closedTickets]);

	return (
		<div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{i18n.t("dashboard.messages.inAttendance.title")}
							</Typography>
							<Grid item>
								<Typography component="h1" variant="h4">
									{GetTickets("open", "true", "false")}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{i18n.t("dashboard.messages.waiting.title")}
							</Typography>
							<Grid item>
								<Typography component="h1" variant="h4">
									{GetTickets("pending", "true", "false")}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{i18n.t("dashboard.messages.closed.title")}
							</Typography>
							<Grid item>
								<Typography component="h1" variant="h4">
									{GetTickets("closed", "true", "false")}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper className={classes.fixedHeightPaper}>
							<Chart onPeakTimeChange={handlePeakTimeChange} />
						</Paper>
					</Grid>
					<Grid item xs={6}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{"Média de Atendimento"}
							</Typography>
							<Grid item>
								<Typography component="h4" variant="h4">
									{`${averageDuration.toFixed(2)} minutos`}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={6}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{"Pico no Atendimento"}
							</Typography>
							<Grid item>
								<Typography component="h4" variant="h4">
									{peakTime}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</div>
	)
}

export default Dashboard